import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import ImageHeader from '../components/ImageHeader'
import CardGrid from '../components/card-grid'
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"


const TermPageTemplate = ({data}) => {
    let termUri = data.wpTermNode.uri
    const fullTermUri = termUri
    termUri = termUri.slice(0, -1)
    termUri = termUri.slice(0, termUri.lastIndexOf("/"))
    const isBooks = fullTermUri.includes('books')

    const termType = data.wpTermNode.nodeType
    return (
        <Layout>
            <Seo
                title={data.wpTermNode.name}
                description={`Browse all resources related to ${data.wpTermNode.name} on Bible to Life.`}
            />
            <ImageHeader title={`Resources in ${data.wpTermNode.name}`}
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title={data.wpTermNode.name} isResource={true} termType={data.wpTermNode.nodeType}
                         termUri={termUri}/>
            <CardGrid items={data.allWpMereResource.edges} termType={termType} isBooks={isBooks}/>
            <Breadcrumbs title={data.wpTermNode.name} isResource={true} termType={data.wpTermNode.nodeType}
                         termUri={termUri}/>
        </Layout>
    )
}

export default TermPageTemplate

export const query = graphql`
    query($id: Int!) {
        wpTermNode(databaseId: {eq: $id}) {
            name
            description
            nodeType
            uri
        }
        wp {
            resourcesGeneralSettings {
                resourceLibraryHeader {
                    headerImage {
                        sourceUrl
                    }
                }
            }
        }
        allWpMereResource(
            sort: {fields: date, order: DESC},
            filter: {terms: {nodes: {elemMatch: {databaseId: {eq: $id}}}}}
        ) {
            edges {
                node {
                    databaseId
                    excerpt
                    slug
                    title
                    uri
                    date
                    multiauthor {
                        nodes {
                            slug
                            name
                        }
                    }
                    resourceTypes {
                      nodes {
                        name
                        slug
                      }
                    }
                    scriptureField {
                        scriptureChapterReference
                    }
                    bookFields {
                        bookCoverImage {
                            mediaDetails {
                                sizes {
                                    sourceUrl
                                    name
                                }
                            }
                        }
                    }
                    featuredImage {
                        node {
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                    }
                    scriptures {
                        nodes {
                            name
                            slug
                        }
                    }
                    topics {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
        }
    }
`